import { template as template_65a2ca9aa11d4426897bdffd1554d078 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './ordered-steps.strict-module.css';
import StepSelectors from './step-selectors';
export default class ApprovalWorkflowFormOrderedSteps extends Component {
    get isRemoveButtonDisabled() {
        // this.args.numberOfOrders conditional can be remove when hasModularPricing is removed
        return this.args.numberOfOrders === 1 || this.args.isPayerStep;
    }
    get stepOrder() {
        return this.args.index + 1;
    }
    get steps() {
        let { ruleset } = this.args;
        return ruleset.steps.filter((step)=>step.order === this.stepOrder);
    }
    @action
    validateOperationSelector(steps) {
        for (let step of steps){
            if (!step.operation) step.errors.add('operation', VALIDATION_ERRORS.STEP_OPERATION_EMPTY);
            else if (step.errors?.get('operation')) step.errors.remove('operation');
        }
    }
    get operationErrors() {
        for (let step of this.steps){
            if (step.errors?.get('operation')) return step.errors?.get('operation');
        }
    }
    @action
    handleClose(steps, ruleset, stepOrder) {
        let invalidUserErrors = [
            VALIDATION_ERRORS.MEMBERSHIP_NO_PERMISSION,
            VALIDATION_ERRORS.MEMBERSHIP_NOT_ACTIVE,
            VALIDATION_ERRORS.MEMBERSHIP_NOT_FOUND,
            VALIDATION_ERRORS.NO_ACTIVE_USER_WITH_ROLE,
            VALIDATION_ERRORS.INACTIVE_MEMBERSHIP,
            VALIDATION_ERRORS.NO_PERMISSION_TO_REVIEW,
            VALIDATION_ERRORS.NO_ACTIVE_USERS,
            VALIDATION_ERRORS.UNSUPPORTED_ROLE
        ];
        // Filter steps of this order that have invalid approvers
        let filteredSteps = ruleset.steps.filter((step)=>{
            let error = step.errors?.get('value');
            if (error && step.order === stepOrder) {
                let isInvalid = invalidUserErrors.includes(error[0]?.message);
                return !isInvalid;
            }
            return true;
        });
        // Handle approvers errors
        for (let step of steps){
            if (!step.value) step.errors.add('value', VALIDATION_ERRORS.STEP_APPROVERS_EMPTY);
            else step.errors.remove('value');
        }
        this.args.updateTriggerMap(); // We call it without arugments to avoid reopening
        if (ruleset.steps.length !== filteredSteps.length) ruleset.steps = filteredSteps;
    }
    get approversErrors() {
        for (let step of this.steps){
            if (step.errors?.get('value')) return step.errors?.get('value');
        }
    }
    get shouldApproversSelectorBeOpen() {
        return this.args.approversSelectorsTriggerMap[this.args.index]?.shouldOpen;
    }
    get lastApproverSelected() {
        return this.args.approversSelectorsTriggerMap[this.args.index]?.lastSelection;
    }
    static{
        template_65a2ca9aa11d4426897bdffd1554d078(`
    <div
      class={{styles.ordered-steps-container}}
      data-test-approval-workflow-form-ordered-steps
      ...attributes
    >
      <p
        class='body-1 {{styles.rule-title}} mb-4'
        data-test-approval-workflow-form-ordered-step-label
      >{{@label}}</p>
      <StepSelectors
        @onApproversChange={{@onApproversChange}}
        @approversOptions={{@steps.approverOptions}}
        @selectedOperation={{@selectedOperation}}
        @handleOperationTypeChange={{@handleOperationTypeChange}}
        @handleStepRemoval={{@handleStepRemoval}}
        @isRemoveButtonDisabled={{this.isRemoveButtonDisabled}}
        @validateOperation={{fn this.validateOperationSelector this.steps}}
        @operationErrors={{this.operationErrors}}
        @approversErrors={{this.approversErrors}}
        @handleApproversSelectorClose={{fn this.handleClose this.steps @ruleset this.stepOrder}}
        @shouldApproversSelectorBeOpen={{this.shouldApproversSelectorBeOpen}}
        @lastApproverSelected={{this.lastApproverSelected}}
        @isPayerStep={{@isPayerStep}}
        data-test-approval-workflow-form-ordered-step
      />
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
