import { template as template_6ff127459aea492d8eaa96dd22e03120 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Timeline from 'qonto/components/stepper/timeline';
import { STATUS } from 'qonto/constants/approval-workflow-state';
import Approvals from '../visualizer/approvals';
import Conditions from '../visualizer/conditions';
import Success from '../visualizer/success';
export default class ApprovalWorkflowStateVisualizer extends Component {
    get hasRequestBeenRejected() {
        let { steps } = this.args.approvalWorkflowState;
        return steps.some((step)=>step.status === STATUS.REJECTED);
    }
    get shouldDisplaySuccessStep() {
        return !this.hasRequestBeenRejected;
    }
    isApprovedStep({ status }) {
        return status === STATUS.APPROVED;
    }
    isCurrentStep({ status }) {
        return status === STATUS.CURRENT;
    }
    isPendingStep({ status }) {
        return status === STATUS.PENDING;
    }
    isRejectedStep({ status }) {
        return status === STATUS.REJECTED;
    }
    filterStepsAfterRejectionStep(steps) {
        let rejectedStepIndex = steps.findIndex((step)=>step.status === STATUS.REJECTED);
        // If no rejection step found or if the rejection is the last step, we don't need to filter the steps
        if (rejectedStepIndex === -1 || rejectedStepIndex === steps.length - 1) {
            return steps;
        }
        return steps.slice(0, rejectedStepIndex + 1);
    }
    get steps() {
        let { steps } = this.args.approvalWorkflowState;
        if (this.hasRequestBeenRejected) {
            return this.filterStepsAfterRejectionStep(steps);
        }
        return steps;
    }
    get lastStep() {
        return this.steps.at(-1);
    }
    isPaymentStep(steps, index) {
        return index === steps.length - 1;
    }
    iconType({ status }) {
        switch(status){
            case STATUS.REJECTED:
                return 'reject';
            case STATUS.CURRENT:
                return 'current';
        }
    }
    paymentIconType({ status }) {
        switch(status){
            case STATUS.REJECTED:
                return 'reject';
            case STATUS.CURRENT:
                return 'payment-current';
            default:
                return 'payment';
        }
    }
    static{
        template_6ff127459aea492d8eaa96dd22e03120(`
    <Timeline ...attributes>
      <:steps as |Step|>
        <Step @lineStyle='solid' @iconType='arrow'>
          <Conditions
            @conditions={{@approvalWorkflowState.conditions}}
            @supplierSnapshot={{@supplierSnapshot}}
          />
        </Step>
        {{#each this.steps as |singleStep index|}}
          {{#unless (this.isPaymentStep this.steps index)}}
            <Step
              @lineStyle={{if (this.isApprovedStep singleStep) 'solid'}}
              @iconType={{this.iconType singleStep}}
              @shouldHideProgressLine={{this.isRejectedStep singleStep}}
            >
              <Approvals
                @step={{singleStep}}
                @requestType={{@requestType}}
                @isWorkflowStateStatus={{true}}
              />
            </Step>
          {{/unless}}
          {{#if (this.isPaymentStep this.steps index)}}
            <Step
              @lineStyle={{if (this.isApprovedStep singleStep) 'solid'}}
              @iconType={{this.paymentIconType singleStep}}
              @shouldHideProgressLine={{this.isRejectedStep singleStep}}
            >
              <Approvals
                @step={{singleStep}}
                @requestType={{@requestType}}
                @isPaymentStep={{true}}
                @isWorkflowStateStatus={{true}}
              />
            </Step>
          {{/if}}
        {{/each}}
        {{#if this.shouldDisplaySuccessStep}}
          <Step
            @iconType={{if (this.isApprovedStep this.lastStep) 'success'}}
            @shouldHideProgressLine={{true}}
          >
            <Success @requestType={{@requestType}} />
          </Step>
        {{/if}}
      </:steps>
    </Timeline>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
